import { all, takeEvery, takeLatest } from 'redux-saga/effects' // fork, take,  takeEvery, takeLeading
import { SAGA_ACTIONS } from '../../_config'
import * as userSaga from './userSaga'
import * as formSaga from './formSaga'
/* import * as merchantSaga from './merchantSaga'
import * as productsSaga from '../sagas/payee/productsSaga'
import * as sitemasterSaga from '../sagas/common/sitemasterSaga'
import * as hppSaga from './hppSaga'
import * as accountsSaga from './accountsSaga'
import * as paymentMethodSaga from './paymentMethodSaga'
import * as invoicesSaga from './invoicesSaga'
import * as productSaga from './productSaga' */

export function* sagas() {
  yield all([
    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.FORGOT_PASSWORD, userSaga.forgotPassword),
    takeLatest(SAGA_ACTIONS.USER.RESET_PASSWORD, userSaga.resetPassword),
    takeLatest(SAGA_ACTIONS.USER.GET_MEMBER_DETAILS, userSaga.getMemberDetails),
    takeLatest(SAGA_ACTIONS.USER.EMPLOYEE_LIST, userSaga.employeeList),
    takeLatest(SAGA_ACTIONS.USER.GET_FORM_CONFIG, userSaga.getFormConfig),
    takeLatest(SAGA_ACTIONS.USER.SUBMIT_DYNAMIC_FORM, userSaga.submitDynamicFrom),
    takeLatest(SAGA_ACTIONS.USER.EMPLOYEE_ACTIVE_INACTIVE, userSaga.memberActivation),
    takeLatest(SAGA_ACTIONS.USER.ADD_EMPLOYEE, userSaga.addEmployee),

    takeLatest(SAGA_ACTIONS.USER.USER_DETAILS, userSaga.userDetails),
    takeLatest(SAGA_ACTIONS.USER.USER_UPDATE, userSaga.userUpdate),
    takeLatest(SAGA_ACTIONS.USER.USER_CHANGE_STATUS, userSaga.userChangeStatus),

    takeLatest(SAGA_ACTIONS.USER.ROLE_ADD, userSaga.addRole),
    takeLatest(SAGA_ACTIONS.USER.ROLE_EDIT, userSaga.editRole),
    takeLatest(SAGA_ACTIONS.USER.ROLE_LIST, userSaga.listRole),
    takeLatest(SAGA_ACTIONS.USER.ROLE_DETAILS, userSaga.detailsRole),
    takeLatest(SAGA_ACTIONS.USER.ROLE_DELETE, userSaga.deleteRole),

    takeLatest(SAGA_ACTIONS.USER.USER_ROLE_MAP, userSaga.userRoleMap),

    takeLatest(SAGA_ACTIONS.USER.USE_CASE_LIST, userSaga.useCaseList),
    takeLatest(SAGA_ACTIONS.USER.USE_CASE_DETAILS, userSaga.useCaseDetails),


    takeLatest(SAGA_ACTIONS.USER.AIRPORT_LIST, userSaga.airportList),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_ADD, userSaga.airportAdd),
    takeLatest(SAGA_ACTIONS.USER.BY_ROLE_NAME, userSaga.getUsersByRoleName),
    takeLatest(SAGA_ACTIONS.USER.CONFIG_BY_ROLE_NAME, userSaga.getConfigByKey),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_EDIT, userSaga.airportEdit),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_DETAILS, userSaga.airportDetails),
    takeLatest(SAGA_ACTIONS.USER.AIRPORT_DELETE, userSaga.airportDelete),

    takeLatest(SAGA_ACTIONS.USER.COMPANY_LIST, userSaga.companyList),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_ADD, userSaga.companyAdd),    
    takeLatest(SAGA_ACTIONS.USER.COMPANY_EDIT, userSaga.companyEdit),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_DETAILS, userSaga.companyDetails),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_DELETE, userSaga.companyDelete),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_UPDATE_STATUS, userSaga.companyStatusUpdate),

    takeLatest(SAGA_ACTIONS.USER.TENANTS_LIST, userSaga.getTenantsAll),
    takeLatest(SAGA_ACTIONS.USER.TENANTS_GET, userSaga.getTenants),
    takeLatest(SAGA_ACTIONS.USER.TENANTS_SAVE, userSaga.tenantSave),
    takeLatest(SAGA_ACTIONS.USER.TENANTS_UPDATE, userSaga.tenantUpdate), 
    takeLatest(SAGA_ACTIONS.USER.TENANTS_DELETE, userSaga.tenantDelete),
    takeLatest(SAGA_ACTIONS.USER.TENANTS_CHANGE_STATUS, userSaga.tenantChangeStatus),

  
    takeEvery(SAGA_ACTIONS.FORM.GET_WORKFLOWS, formSaga.getWorkflows),
    takeEvery(SAGA_ACTIONS.FORM.GET_MODULES, formSaga.getModules),
    takeEvery(SAGA_ACTIONS.FORM.GET_FORMS, formSaga.getForms),
    takeEvery(SAGA_ACTIONS.FORM.GET_CONTROL_TYPES, formSaga.getControlTypes),
    takeEvery(SAGA_ACTIONS.FORM.GET_CONTROL_PROPS, formSaga.getControlProps),
    takeEvery(SAGA_ACTIONS.FORM.SAVE, formSaga.save),


    takeLatest(SAGA_ACTIONS.USER.SYSTEM_CONFIG_LIST, userSaga.getSystemConfigAll),
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_CONFIG_GET, userSaga.getSystemConfig),
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_CONFIG_SAVE, userSaga.systemConfigSave),
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_CONFIG_UPDATE, userSaga.systemConfigUpdate), 
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_CONFIG_DELETE, userSaga.systemConfigDelete),
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_CONFIG_CHANGE_STATUS, userSaga.systemConfigChangeStatus),

    takeLatest(SAGA_ACTIONS.USER.SYSTEM_ENUMS_LIST, userSaga.getSystemEnumsAll),
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_ENUMS_GET, userSaga.getSystemEnums),
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_ENUMS_SAVE, userSaga.systemEnumsSave),
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_ENUMS_UPDATE, userSaga.systemEnumsUpdate), 
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_ENUMS_DELETE, userSaga.systemEnumsDelete),
    takeLatest(SAGA_ACTIONS.USER.SYSTEM_ENUMS_CHANGE_STATUS, userSaga.systemEnumsChangeStatus),
    
    takeLatest(SAGA_ACTIONS.USER.COUNTRY_LIST, userSaga.getCountryList),
    takeLatest(SAGA_ACTIONS.USER.STATE_LIST, userSaga.getStateList),
    takeLatest(SAGA_ACTIONS.USER.CURRENCY_LIST, userSaga.getCurrencyList),

    takeLatest(SAGA_ACTIONS.USER.COMPANY_CONTACT_DETAILS, userSaga.companyContactsDetails),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_CONTACT_LIST, userSaga.companyContactsList),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_CONTACT_ALL_LIST, userSaga.companyContactsAllList),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_CONTACT_ADD, userSaga.companyContactsAdd), 
    takeLatest(SAGA_ACTIONS.USER.COMPANY_CONTACT_EDIT, userSaga.companyContactsEdit),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_CONTACT_DELETE, userSaga.companyContactsDelete),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_CONTACT_STATUS, userSaga.companyContactsStatus),

    takeLatest(SAGA_ACTIONS.USER.COMPANY_URLS_DETAILS, userSaga.companyUrlsDetails),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_URLS_LIST, userSaga.companyUrlsList),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_URLS_ALL_LIST, userSaga.companyUrlsAllList),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_URLS_ADD, userSaga.companyUrlsAdd), 
    takeLatest(SAGA_ACTIONS.USER.COMPANY_URLS_EDIT, userSaga.companyUrlsEdit),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_URLS_DELETE, userSaga.companyUrlsDelete),
    takeLatest(SAGA_ACTIONS.USER.COMPANY_URLS_STATUS, userSaga.companyUrlsStatus),

    /* takeLatest(SAGA_ACTIONS.COMMON.SITE_MASTER, sitemasterSaga.getSiteMaster),
    takeLatest(SAGA_ACTIONS.USER.LOGIN, userSaga.login),
    takeLatest(SAGA_ACTIONS.USER.ME, userSaga.fetchCurrentUser),
    takeLatest(SAGA_ACTIONS.USER.LOGOUT, userSaga.logout),
    takeLatest(SAGA_ACTIONS.USER.SIGNUP, userSaga.signup),
    takeLatest(SAGA_ACTIONS.USER.MERCHANT_SIGNUP, userSaga.merchantSignup),
    takeLatest(SAGA_ACTIONS.USER.VERIFY_ACCOUNT, userSaga.verifyAccount),
    takeLatest(SAGA_ACTIONS.USER.REVERIFY_ACCOUNT, userSaga.reVerifyAccount),
    takeLatest(SAGA_ACTIONS.USER.UPDATE_USER, userSaga.updateUser),
    takeLatest(SAGA_ACTIONS.USER.SESSIONS_LIST, userSaga.getSessionList), */

    takeLatest(SAGA_ACTIONS.USER.DISCOVERY_GET_SINGLE, userSaga.getDiscovery),
    takeLatest(SAGA_ACTIONS.USER.DISCOVERY_GET_ALL, userSaga.getAllDiscovery),


    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.LIST, formSaga.getAllScoreQuestions),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.SAVE, formSaga.scoreQuestionSave),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.UPDATE, formSaga.scoreQuestionUpdate),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.DETAILS, formSaga.getScoreQuestions),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.CHANGE_STATUS, formSaga.scoreQuestionStatusChange),
    takeLatest(SAGA_ACTIONS.SCORE_QUESTION.DELETE, formSaga.scoreQuestionDelete),

  ])
}
