import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Signup from './pages/auth/Singnup';
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';

import IdeaList from './pages/intectFlow/IdeaList';
import TestPlanTwo from './pages/TestPlanTwo';
import Dashboard from './pages/Dashboard';
import Discovery from './pages/Discovery';
import IdeaListTwo from './pages/intectFlow/IdeaListTwo';
import IntactForm from './pages/intectFlow/IntactForm';
import ChallengeStatement from './pages/ChallengeStatement';
import SolutionFraming from './pages/SolutionFraming';
import RankingPrioritization from './pages/RankingPrioritization';
import HangerDecision from './pages/HangerDecision';
import ProjectPlan from './pages/ProjectPlan';
import ProcurementPlan from './pages/ProcurementPlan';
import TestPlan from './pages/TestPlan';
import InFieldTesting from './pages/InFieldTesting';
import DayInTheLife from './pages/DayInTheLife';
import FieldAcceptance from './pages/FieldAcceptance';
import FinalGoNogoDecision from './pages/FinalGoNogoDecision';
import KpiTrendingdashboard from './pages/KpiTrendingdashboard';
import PreiodComprisonchatDashboard from './pages/PreiodcomprisonchatDashboard';
import ProfileCreate from './pages/ProfileCreate';
import { IdeaSubmit } from './pages/intectFlow/IdeaSubmit';
import { AddEmployee } from './pages/employee/addEmployee';
import PrivateRoutes from './_config/privateRoutes';
import { RoleList } from './pages/role/RoleList';
import { RoleAddEdit } from './pages/role/RoleAddEdit';
import { UseCaseDetails } from './pages/intectFlow/UseCaseDetails';
import { CreateForm } from './pages/form/createForm';
import { AirportList } from './pages/airport/AirportList';
import { AirportAddEdit } from './pages/airport/AirportAddEdit';

import { CompanyList } from './pages/company/CompanyList';
import { CompanyAddEdit } from './pages/company/CompanyAddEdit';
import { TenantsList } from './pages/tenants/TenantsList';
import { URLS } from './_config';
import { TenantForm } from './pages/tenants/TenantForm';
import { SystemConfigurationList } from './pages/systemConfiguration/SystemConfigurationList';
import { SystemConfigurationForm } from './pages/systemConfiguration/SystemConfigurationForm';
import EmployeeList from './pages/employee/EmployeeList';
import { ContactList } from './pages/company/ContactList';
import { DomainList } from './pages/company/DomainList';
import { ContactAddEdit } from './pages/company/ContactAddEdit';
import { SysEnumsList } from './pages/sysEnums/SysEnumsList';
import { SysEnumsForm } from './pages/sysEnums/SysEnumsForm';
import { DomainAddEdit } from './pages/company/DomainAddEdit';
import { EmployeeRolePermission } from './pages/employee/EmployeeRolePermission';
import { ScoreQuestionsList } from './pages/scoreQuestions/ScoreQuestionsList';
import { ScoreQuestionsForm } from './pages/scoreQuestions/ScoreQuestionsForm';



function App() {
    return (
        <Router>
            <div className="App">
                <div className="d-flex flex-column min-vh-100">

                    <Routes>
                        <Route path='/signup' element={<Signup></Signup>}></Route>
                        <Route path='/forgot-password' element={<ForgotPassword></ForgotPassword>}></Route>
                        <Route path='/reset-password' element={<ResetPassword></ResetPassword>}></Route>
                        <Route path='/login' element={<Login></Login>}></Route>
                        <Route path='/' element={<Login></Login>}></Route>

                        <Route element={<PrivateRoutes />}>
                            <Route path='/intact-form' element={<IntactForm></IntactForm>}></Route>
                            <Route path='/idea-submit' element={<IdeaSubmit></IdeaSubmit>}></Route>

                            <Route path={URLS.EMPLOYEE_LIST} element={<EmployeeList />}></Route>
                            <Route path={URLS.EMPLOYEE_CREATE} element={<AddEmployee />}></Route>
                            <Route path={URLS.EMPLOYEE_EDIT + ':id'} element={<AddEmployee />}></Route>
                            <Route path={URLS.EMPLOYEE_ROLE_PERMISSION + ':id'} element={<EmployeeRolePermission />}></Route> 

                            <Route path='/role/list' element={<RoleList />}></Route>
                            <Route path='/role/create' element={<RoleAddEdit />}></Route>
                            <Route path='/role/:id' element={<RoleAddEdit />}></Route>
                            <Route path='/use-case/:id' element={<UseCaseDetails />}></Route>
                            <Route path='/airport/list' element={<AirportList />}></Route>
                            <Route path='/airport/create' element={<AirportAddEdit />}></Route>
                            <Route path='/airport/:id' element={<AirportAddEdit />}></Route>


                            <Route path='/company/list' element={<CompanyList />}></Route>
                            <Route path='/company/create' element={<CompanyAddEdit />}></Route>
                            <Route path='/company/:id' element={<CompanyAddEdit />}></Route>


                            <Route path={URLS.TENANTS} element={<TenantsList />}></Route>
                            <Route path={URLS.TENANTS_CREATE} element={<TenantForm />}></Route>
                            <Route path={URLS.TENANTS_EDIT + ':id'} element={<TenantForm />}></Route>
                            <Route path={URLS.SYSTEM_CONFIGURATION} element={<SystemConfigurationList />}></Route>
                            <Route path={URLS.SYSTEM_CONFIGURATION_CREATE} element={<SystemConfigurationForm />}></Route>
                            <Route path={URLS.SYSTEM_CONFIGURATION_EDIT + ':id'} element={<SystemConfigurationForm />}></Route>

                            <Route path={URLS.SYSTEM_ENUMS} element={<SysEnumsList />}></Route>
                            <Route path={URLS.SYSTEM_ENUMS_CREATE} element={<SysEnumsForm />}></Route>
                            <Route path={URLS.SYSTEM_ENUMS_EDIT + ':id'} element={<SysEnumsForm />}></Route>


                            <Route path='/form' element={<CreateForm />}></Route>


                            <Route path='/use-case-library-list' element={<IdeaList></IdeaList>}></Route>
                            <Route path='/use-case-library-grid' element={<IdeaListTwo></IdeaListTwo>}></Route>


                            <Route path={URLS.SYSTEM_CONFIGURATION} element={<SystemConfigurationList />}></Route>
                            <Route path={URLS.SYSTEM_CONFIGURATION_CREATE} element={<SystemConfigurationForm />}></Route>
                            <Route path={URLS.SYSTEM_CONFIGURATION_EDIT + ':id'} element={<SystemConfigurationForm />}></Route>

                            <Route path='/company/domains/:id' element={<DomainList />}></Route>
                            <Route path='/company/contacts/:id' element={<ContactList />}></Route>
                            <Route path='/company/contact/:comid/create' element={<ContactAddEdit />}></Route>
                            <Route path='/company/contact/:comid/:id' element={<ContactAddEdit />}></Route>

                            <Route path='/company/domain/:domainid/create' element={<DomainAddEdit />}></Route>
                            <Route path='/company/domain/:domainid/:id' element={<DomainAddEdit />}></Route>


                            <Route path={URLS.SCORE_QUESTION.LIST} element={<ScoreQuestionsList />}></Route>
                            <Route path={URLS.SCORE_QUESTION.CREATE} element={<ScoreQuestionsForm />}></Route>
                            <Route path={URLS.SCORE_QUESTION.EDIT} element={<ScoreQuestionsForm />}></Route>

                        </Route>

                        


                        <Route path='/use-case-library-list' element={<IdeaList></IdeaList>}></Route>
                        <Route path='/use-case-library-grid' element={<IdeaListTwo></IdeaListTwo>}></Route>


                        <Route path='/use-case-library-list' element={<IdeaList></IdeaList>}></Route>
                        <Route path='/use-case-library-grid' element={<IdeaListTwo></IdeaListTwo>}></Route>

                        <Route path='/discovery' element={<Discovery></Discovery>}></Route>


                        <Route path='/profile-create' element={<ProfileCreate></ProfileCreate>}></Route>


                        <Route path='/challenge-statement' element={<ChallengeStatement></ChallengeStatement>}></Route>
                        <Route path='/solution-framing' element={<SolutionFraming></SolutionFraming>}></Route>
                        <Route path='/ranking-prioritization' element={<RankingPrioritization></RankingPrioritization>}></Route>

                        <Route path='/hanger-decision' element={<HangerDecision></HangerDecision>}></Route>

                        <Route path='/project-plan' element={<ProjectPlan></ProjectPlan>}></Route>
                        <Route path='/procurement-plan' element={<ProcurementPlan></ProcurementPlan>}></Route>

                        <Route path='/test-plan' element={<TestPlan></TestPlan>}></Route>
                        <Route path='/in-field-testing' element={<InFieldTesting></InFieldTesting>}></Route>
                        <Route path='/day-in-the-life' element={<DayInTheLife></DayInTheLife>}></Route>
                        <Route path='/field-acceptance' element={<FieldAcceptance></FieldAcceptance>}></Route>
                        <Route path='/final-go-nogo-decision' element={<FinalGoNogoDecision></FinalGoNogoDecision>}></Route>

                        <Route path='/kpi-trendingdashboard' element={<KpiTrendingdashboard></KpiTrendingdashboard>}></Route>

                        <Route path='/preiod-comprisonchat-dashboard' element={<PreiodComprisonchatDashboard></PreiodComprisonchatDashboard>}></Route>

                        <Route path='/test-plan-two' element={<TestPlanTwo></TestPlanTwo>}></Route>
                        <Route path='/dashboard' element={<Dashboard></Dashboard>}></Route>

                    </Routes>
                </div>
            </div>
        </Router>
    );
}

export default App;
